import fire from "./fire";
import firebase from "firebase/app";
import "firebase/firestore";

export async function getSiteSettingsDB() {
  const db = fire.firestore();
  const settings = await db.doc(`settings/web`).get();

  if (settings && settings.exists) {
    return settings.data();
  }
  return null;
}

export async function incrementJobsField(actor = "") {
  const db = fire.firestore();
  const settingsRef = db.doc("settings/web");

  try {
    await db.runTransaction(async (transaction) => {
      const newJobs = firebase.firestore.FieldValue.increment(1);
      if (actor !== "") {
        transaction.update(settingsRef, { jobs: newJobs, [actor]: newJobs });
      } else {
        transaction.update(settingsRef, { jobs: newJobs });
      }
    });
  } catch (error) {
    console.error("Error incrementing jobs field:", error);
  }
}

export async function decrementJobsField(actor = "") {
  const db = fire.firestore();
  const settingsRef = db.doc("settings/web");

  try {
    await db.runTransaction(async (transaction) => {
      const newJobs = firebase.firestore.FieldValue.increment(-1);

      if (actor !== "") {
        transaction.update(settingsRef, { jobs: newJobs, [actor]: newJobs });
      } else {
        transaction.update(settingsRef, { jobs: newJobs });
      }
    });
  } catch (error) {
    console.error("Error decrementing jobs field:", error);
  }
}
