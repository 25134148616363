import fire from "../../../services/fire";
import { isValidEmail } from "../../../services/validation";

export const ROLE_ID_SU = "100";
export const ROLE_ID_SA = "101";
export const ROLE_ID_SA_FB_POSTER = "102";
export const ROLE_ID_AG_SU = "200";
export const ROLE_ID_AG_SA = "201";
export const ROLE_ID_CHROME_EXT = "300";

export const userroles = [
  { _id: ROLE_ID_SU, name: "Super Admin" },
  { _id: ROLE_ID_SA, name: "System Admin" },
  { _id: ROLE_ID_SA_FB_POSTER, name: "System FB Poster" },
  { _id: ROLE_ID_AG_SU, name: "Agency Super Admin" },
  { _id: ROLE_ID_AG_SA, name: "Agency System Admin" },
  { _id: ROLE_ID_CHROME_EXT, name: "Chrome Extension" },
];

export function getUserRoles() {
  return userroles;
}

export function getUserRoleNameById(id) {
  const [role] = userroles.filter((g) => g._id === id);

  return role.name;
}

export async function getUserRoleByEmail(email) {
  let emailtrim = email.trim();
  if (!isValidEmail(emailtrim)) {
    return null;
  }

  const db = fire.firestore();

  const parishioners = await db
    .collection("roles")
    .where("email", "==", emailtrim)
    .get();
  const list = [];
  parishioners.forEach((doc) => {
    list.push(doc.data());
  });
  return list;
}

export async function getUserRoleById(id) {
  if (!id) {
    return null;
  }

  const db = fire.firestore();

  const parishioners = await db
    .collection("roles")
    .where("authid", "==", id)
    .get();
  const list = [];
  parishioners.forEach((doc) => {
    list.push(doc.data());
  });
  return list;
}

export function isSystemValidatorRole(user) {
  if (!user) {
    return false;
  }
  if (user.hasOwnProperty("systemvalidator")) {
    return true;
  }

  return false;
}

export function isAttendanceVerifierRole(user) {
  if (!user) {
    return false;
  }
  if (user.hasOwnProperty("attendanceverifier")) {
    return true;
  }

  return false;
}

export async function isSuperAdminRole(user) {
  if (!user) {
    return false;
  }
  const idTokenResult = await user.getIdTokenResult();
  if (idTokenResult.claims.superadmin) {
    return true;
  }

  return false;
}

export async function isSystemFBPosterRole(user) {
  if (!user) {
    return false;
  }
  const idTokenResult = await user.getIdTokenResult();
  if (idTokenResult.claims.systemfbposter) {
    return true;
  }

  return false;
}

export async function isAgencySuperAdminRole(user) {
  if (!user) {
    return false;
  }
  const idTokenResult = await user.getIdTokenResult();
  if (idTokenResult.claims.agencysuperadmin) {
    return true;
  }

  return false;
}

export async function isAgencySystemAdminRole(user) {
  if (!user) {
    return false;
  }
  const idTokenResult = await user.getIdTokenResult();
  if (idTokenResult.claims.agencysystemadmin) {
    return true;
  }

  return false;
}

export async function isChromeExtensionRole(user) {
  if (!user) {
    return false;
  }
  const idTokenResult = await user.getIdTokenResult();
  if (idTokenResult.claims.chromeextension) {
    return true;
  }

  return false;
}

export function isParishPriestRole(user) {
  if (!user) {
    return false;
  }
  if (user.hasOwnProperty("parishpriest")) {
    return true;
  }

  return false;
}

export function isArchbishopRole(user) {
  if (!user) {
    return false;
  }
  if (user.hasOwnProperty("archbishop")) {
    return true;
  }

  return false;
}

export function isSecretaryRole(user) {
  if (!user) {
    return false;
  }
  if (user.hasOwnProperty("secretary")) {
    return true;
  }

  return false;
}

export function isSupportRole(user) {
  if (!user) {
    return false;
  }
  if (user.hasOwnProperty("support")) {
    return true;
  }

  return false;
}

export async function isSystemAdminRole(user) {
  if (!user) {
    return false;
  }
  const idTokenResult = await user.getIdTokenResult();
  if (idTokenResult.claims.systemadmin) {
    return true;
  }

  return false;
}

export function isAPIConsumerRole(user) {
  if (!user) {
    return false;
  }
  if (user.hasOwnProperty("apiconsumer")) {
    return true;
  }

  return false;
}
