import React from "react";
import loadable from "@loadable/component";
import CenterSpinner from "../../common/spinner";

function AsyncUpdateCV({ ...rest }) {
  const AsyncLoad = loadable(() => import("../page/uploadCV"));
  return <AsyncLoad {...rest} fallback={<CenterSpinner />} />;
}

export default AsyncUpdateCV;
