import React from "react";
import loadable from "@loadable/component";
import CenterSpinner from "./common/spinner";

function AsyncHome({ ...rest }) {
  const AsyncLoad = loadable(() => import("./home"));
  return <AsyncLoad {...rest} fallback={<CenterSpinner />} />;
}

export default AsyncHome;
