import React from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { emojisplosion } from "emojisplosion";
// import { rotateDailyArray } from "../../../utils/obj";

const images = [
  "https://firebasestorage.googleapis.com/v0/b/seaman-ph.appspot.com/o/cdn%2Ftestimonials%2Fshawn.png?alt=media&token=f66b5011-b2e7-498d-b174-8f4cd1a8aea6",
  "https://firebasestorage.googleapis.com/v0/b/seaman-ph.appspot.com/o/cdn%2Ftestimonials%2Felton.png?alt=media&token=fed5ed00-7cf0-4a80-9283-db6d88173318",
  "https://firebasestorage.googleapis.com/v0/b/seaman-ph.appspot.com/o/cdn%2Ftestimonials%2Fjhareld.png?alt=media&token=c309a894-4d88-4ded-b2b4-ffdd1f07dceb",
  "https://firebasestorage.googleapis.com/v0/b/seaman-ph.appspot.com/o/cdn%2Ftestimonials%2Fram.png?alt=media&token=e055ed80-b874-4158-ad27-2733784af95a",
  "https://firebasestorage.googleapis.com/v0/b/seaman-ph.appspot.com/o/cdn%2Ftestimonials%2Ffelipe.png?alt=media&token=4c29560e-3e06-48ea-a16a-e8a00b80393e",
  "https://firebasestorage.googleapis.com/v0/b/seaman-ph.appspot.com/o/cdn%2Ftestimonials%2Fphel.png?alt=media&token=3f18336b-89c1-4885-8673-96f756ddd78d",
  "https://firebasestorage.googleapis.com/v0/b/seaman-ph.appspot.com/o/cdn%2Ftestimonials%2Fnheil.png?alt=media&token=bcc4e7a4-0d59-4483-8724-d0f14446a982",
  "https://firebasestorage.googleapis.com/v0/b/seaman-ph.appspot.com/o/cdn%2Ftestimonials%2Fnay.png?alt=media&token=bc28f4ad-cf0e-4946-abcb-39dbb72c531d",
  "https://firebasestorage.googleapis.com/v0/b/seaman-ph.appspot.com/o/cdn%2Ftestimonials%2Fronie.png?alt=media&token=3c8468ae-4ace-4227-a38a-983c89639641",
  "https://firebasestorage.googleapis.com/v0/b/seaman-ph.appspot.com/o/cdn%2Ftestimonials%2Fcris.png?alt=media&token=8a882afe-09ff-49df-93aa-f019ca9c6615",
  "https://firebasestorage.googleapis.com/v0/b/seaman-ph.appspot.com/o/cdn%2Ftestimonials%2Fjay.png?alt=media&token=0695fbca-5339-46bc-b079-afda2b454c35",
  "https://firebasestorage.googleapis.com/v0/b/seaman-ph.appspot.com/o/cdn%2Ftestimonials%2Ffrancis.png?alt=media&token=16c4ffab-c6a7-4b67-ae5b-0d752f8f46e2",
  "https://firebasestorage.googleapis.com/v0/b/seaman-ph.appspot.com/o/cdn%2Ftestimonials%2Fsan.png?alt=media&token=f253f635-6af2-490f-8899-6f80edad14d0",
  "https://firebasestorage.googleapis.com/v0/b/seaman-ph.appspot.com/o/cdn%2Ftestimonials%2Fhermi.png?alt=media&token=ae34f946-2fe1-4568-9991-7eb510e35206",
  "https://firebasestorage.googleapis.com/v0/b/seaman-ph.appspot.com/o/cdn%2Ftestimonials%2Fluc.png?alt=media&token=96a36d84-be23-4fee-999b-13208737c528",
  "https://firebasestorage.googleapis.com/v0/b/seaman-ph.appspot.com/o/cdn%2Ftestimonials%2Fjn.png?alt=media&token=aff037bb-54fc-459a-ba24-c6d65fc409c5",
  "https://firebasestorage.googleapis.com/v0/b/seaman-ph.appspot.com/o/cdn%2Ftestimonials%2Fjerald.png?alt=media&token=15efb547-ae92-48bd-8c4a-43977f268674",
  "https://firebasestorage.googleapis.com/v0/b/seaman-ph.appspot.com/o/cdn%2Ftestimonials%2Fjohnpaulpineda.png?alt=media&token=815ce15a-5731-4b70-86fd-9f504d65f6a4",
  "https://firebasestorage.googleapis.com/v0/b/seaman-ph.appspot.com/o/cdn%2Ftestimonials%2Fyancha.png?alt=media&token=d93e4322-7f9c-4de1-8f1d-3e6ffa3f4b1b",

  "",
];

const color = [
  "alert-warning",
  "alert-success",
  "alert-primary",
  "alert-danger",
  "alert-info",
  "alert-secondary",
];

const emoji = [
  "❤️",
  "🥰",
  "🥳",
  "🔥",
  "✅",
  "👏",
  "🔥",
  "😍",
  "👌",
  "👍",
  "🔥",
  "🫡",
  "🙌",
  "🎉",
  "🙏",
  "🎁",
  "🏆",
  "🔥",
  "❤️",
  "🙏",
];

function Testimonials({
  settings = { 270: 1, 350: 1, 750: 2, 800: 2, 900: 2 },
  showFeedback = true,
}) {
  const rotatedData = images; //rotateDailyArray(images);
  const Feedback = () => (
    <div className="text-center">
      <a
        className={`roundcornernocolorbutton text-truncates btn btn-success`}
        target="_blank"
        rel="noopener noreferrer"
        href={`mailto:jeyner@seamanbio.ph?subject=Feedback`}>
        Click to Send Your Feedback{" "}
        <span role="img" aria-label="feedback">
          ✏️
        </span>
      </a>
    </div>
  );

  return (
    <div>
      <h2
        style={{ userSelect: "none" }}
        className="mt-0 mb-4 text-center pointer"
        onClick={(event) => {
          const x = event.clientX;
          const y = event.clientY;
          emojisplosion({
            position() {
              return {
                x: x,
                y: y,
              };
            },
          });
        }}>
        Member Feedback{" "}
        <span role="img" aria-label="emoji">
          ❤️
        </span>
      </h2>
      {showFeedback && (
        <div className="mb-4">
          <Feedback />
        </div>
      )}
      <ResponsiveMasonry columnsCountBreakPoints={settings}>
        <Masonry columnsCount={3} gutter="12px">
          {rotatedData.map((t, i) => {
            let count = i;

            if (count > 5) count = count % 5;
            const randomColor = color[count];
            const emo = emoji[i];

            return (
              <div
                onClick={(event) => {
                  const rect = event.target.getBoundingClientRect();
                  const x = rect.right;
                  const y = rect.bottom;
                  emojisplosion({
                    emojis: [emo],
                    position() {
                      return {
                        x: x,
                        y: y,
                      };
                    },
                  });
                }}
                className={`alert pointer card ${randomColor} shadow roundcornernocolor zoom p-1 m-1`}
                key={i}>
                {/* <div className="card-body p-2"> */}
                <div className="m-1 pr-2 responsive">
                  {t !== "" ? (
                    <img
                      className="responsive img-fluid"
                      style={{
                        border: "6px dashed #fff",
                        userSelect: "none",
                        borderRadius: "8px",
                        boxShadow: "1px 1px 10px -4px #888888",
                      }}
                      src={t}
                      alt="Testimonials"
                    />
                  ) : (
                    <div
                      style={{
                        border: "6px dashed #fff",
                        userSelect: "none",
                      }}>
                      <div className="mt-5 mb-3 font-italic">
                        <div className="font-weight-bold defaultfontsize mb-1 font-italic">
                          Sa susunod, ikaw naman!
                          <br />
                          <span role="img" aria-label="pray">
                            👏👏👏
                          </span>
                        </div>
                        ~ SeamanBio.PH Team
                      </div>
                    </div>
                  )}
                  <div
                    // onClick={(event) => {
                    //   const x = event.clientX;
                    //   const y = event.clientY;
                    //   emojisplosion({
                    //     emojis: [emo],
                    //     position() {
                    //       return {
                    //         x: x,
                    //         y: y,
                    //       };
                    //     },
                    //   });
                    // }}
                    style={{
                      position: "absolute",
                      bottom: "-20px",
                      right: "-10px",
                      userSelect: "none",
                    }}>
                    <span
                      role="img"
                      aria-label="smiley"
                      style={{ fontSize: "40px" }}>
                      {emo}
                    </span>
                  </div>
                </div>
                {/* </div> */}
              </div>
            );
          })}
        </Masonry>
      </ResponsiveMasonry>
      <div className="alert alert-primary mt-4">
        Please let us know how SeamanBio has helped you so we can feature you as
        well!!!
      </div>
      {showFeedback && (
        <div className="mt-4">
          <Feedback />
        </div>
      )}
    </div>
  );
}

export default Testimonials;
