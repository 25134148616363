/*
  let newDate = endDate;
  if (endDate === "Present") {
    newDate = new Date();
  }
  // Convert the start and end dates to JavaScript Date objects.
  const start = new Date(startDate);
  const end = new Date(newDate);

*/

export const IMMEDIATE_AVAILABILITY = 4102416000000;

export function convertMonthsToArray(months) {
  const years = Math.floor(months / 12);
  const remainingMonths = months % 12;
  return [years, remainingMonths];
}

export function convertMonthsToString(months) {
  const years = Math.floor(months / 12);
  const remainingMonths = months % 12;

  let result = "";
  if (years > 0) {
    result += `${years} yr${years > 1 ? "s" : ""}`;
  }
  if (remainingMonths > 0) {
    if (years > 0) {
      result += " ";
    }
    result += `${remainingMonths} mo${remainingMonths > 1 ? "s" : ""}`;
  }
  if (years === 0 && remainingMonths === 0) {
    result = "0 mos";
  }
  return result;
}

export function convertYearsAndMonthsToTotalMonths(years, months) {
  return years * 12 + months;
}

export function generateAvailability(currentDate = new Date()) {
  const immediate = normaliseDate(new Date("01 Jan 2100"))?.getTime();
  const availability = [{ _id: immediate, name: "Immediate" }];
  const MAX_AVAILABILITY = 9;
  for (let i = 1; i <= MAX_AVAILABILITY; i++) {
    const futureDate = new Date(currentDate);
    futureDate.setMonth(currentDate.getMonth() + i);

    const month = futureDate.toLocaleString("default", { month: "short" });
    const year = futureDate.getFullYear();
    let when = "";
    // if (i === 1) {
    //   when = "(Next month)";
    // } else {
    //   when = `(${i} months)`;
    // }
    const availabilityEntry = {
      _id: normaliseDate(new Date(`01 ${month} ${year}`)).getTime(),
      name: `${month} ${year} ${when}`,
    };

    availability.push(availabilityEntry);
  }

  return availability;
}

export function getDaysInMilliseconds() {
  return 1000 * 60 * 60 * 24;
}

export function getDaySinceStartDate() {
  const START_ROUND_ROBIN_DATE = "8 Aug 2024";
  const now = new Date();
  const startDate = new Date(START_ROUND_ROBIN_DATE); // 8 August 2024 (month is 0-indexed)
  const diff = now - startDate;
  const oneDay = 1000 * 60 * 60 * 24;
  const daySinceStartDate = Math.floor(diff / oneDay);
  return daySinceStartDate;
}

export function getDateRange(when = new Date(), extramonth = 0) {
  const start = new Date(when);
  const end = new Date(when);
  const startstr = start?.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
  end.setMonth(start.getMonth() + extramonth); // Add exactly 1 month
  const endstr = end?.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
  const daterange = `${startstr} - ${endstr}`;
  console.log(daterange);
  return daterange;
}

export function normaliseDate(date = new Date()) {
  if (date === null || date === undefined) {
    return new Date();
  }
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
}

export function dateDifference(start, end) {
  let style = 4;
  if (style === 1) {
    return dateDifferenceV1(start, end);
  } else if (style === 2) {
    return dateDifferenceV2(start, end);
  } else if (style === 3) {
    return dateDifferenceV3(start, end);
  } else if (style === 4) {
    return dateDifferenceV4(start, end);
  }
}

export function dateDifferenceV5(start, end) {
  let newDate = end;
  if (end === "Present") {
    newDate = new Date();
  }
  const startDate = new Date(start);
  const endDate = new Date(newDate);
  let years = endDate.getFullYear() - startDate.getFullYear();
  let months = endDate.getMonth() - startDate.getMonth();
  let days = endDate.getDate() - startDate.getDate();

  if (days < 0) {
    months--;
    const lastDayOfMonth = new Date(
      endDate.getFullYear(),
      endDate.getMonth(),
      0
    ).getDate();
    days = lastDayOfMonth - startDate.getDate() + endDate.getDate();
  }

  if (months < 0) {
    years--;
    months = 12 + months;
  }

  const result = [];
  if (years > 0) {
    result.push(`${years}y${years > 1 ? "" : ""}`);
  }
  if (months > 0) {
    result.push(`${months}m${months > 1 ? "" : ""}`);
  }
  // console.log(years, months);
  if (days > 0) {
    // if (years === 0 && months === 0 && days > 0) {
    result.push(`${days}d${days !== 1 ? "" : ""}`);
  }

  return result.join(" ");
}

export function dateDifferenceV4(start, end) {
  let newDate = end;
  if (end === "Present") {
    newDate = new Date();
  }
  const startDate = new Date(start);
  const endDate = new Date(newDate);
  let years = endDate.getFullYear() - startDate.getFullYear();
  let months = endDate.getMonth() - startDate.getMonth();
  let days = endDate.getDate() - startDate.getDate();

  if (days < 0) {
    months--;
    const lastDayOfMonth = new Date(
      endDate.getFullYear(),
      endDate.getMonth(),
      0
    ).getDate();
    days = lastDayOfMonth - startDate.getDate() + endDate.getDate();
  }

  if (months < 0) {
    years--;
    months = 12 + months;
  }

  const result = [];
  if (years > 0) {
    result.push(`${years} yr${years > 1 ? "s" : ""}`);
  }
  if (months > 0) {
    result.push(`${months} mo${months > 1 ? "s" : ""}`);
  }
  // console.log(years, months);
  if (days > 0) {
    // if (years === 0 && months === 0 && days > 0) {
    result.push(`${days} day${days !== 1 ? "s" : ""}`);
  }

  return result.join(" ");
}

export function dateDifferenceV3(start, end) {
  let newDate = end;
  if (end === "Present") {
    newDate = new Date();
  }
  const startDate = new Date(start);
  const endDate = new Date(newDate);

  let years = endDate.getFullYear() - startDate.getFullYear();
  let months = endDate.getMonth() - startDate.getMonth();
  let days = endDate.getDate() - startDate.getDate();

  if (days < 0) {
    months--;
    const lastDayOfMonth = new Date(
      endDate.getFullYear(),
      endDate.getMonth(),
      0
    ).getDate();
    days += lastDayOfMonth;
  }

  if (months < 0) {
    years--;
    months += 12;
  }

  const yearStr = years > 0 ? `${years} yr${years > 1 ? "s" : ""}` : "";
  const monthStr = months > 0 ? `${months} mo${months > 1 ? "s" : ""}` : "";
  const dayStr = days > 0 ? `${days} day${days > 1 ? "s" : ""}` : "";

  const result = [yearStr, monthStr, dayStr].filter((part) => part).join(" ");

  return result;
}

export function dateDifferenceV2(start, end) {
  let newDate = end;
  if (end === "Present") {
    newDate = new Date();
  }
  const startDate = new Date(start);
  const endDate = new Date(newDate);

  let years = endDate.getFullYear() - startDate.getFullYear();
  let months = endDate.getMonth() - startDate.getMonth();
  let days = endDate.getDate() - startDate.getDate();

  if (days < 0) {
    months--;
    const lastMonth = new Date(
      endDate.getFullYear(),
      endDate.getMonth() - 1,
      startDate.getDate()
    );
    days = Math.floor((endDate - lastMonth) / (24 * 60 * 60 * 1000));
  }

  if (months < 0) {
    years--;
    months += 12;
  }

  if (years === 0 && months === 0 && days === 0) {
    return "";
  }

  const result = [];
  if (years > 0) {
    result.push(`${years} yr${years > 1 ? "s" : ""}`);
  }
  if (months > 0) {
    result.push(`${months} mo${months > 1 ? "s" : ""}`);
  }
  if (days > 0) {
    result.push(`${days} day${days > 1 ? "s" : ""}`);
  }

  return result.join(" ");
}

export function dateDifferenceV1(date1, date2) {
  let newDate = date2;
  if (date2 === "Present") {
    newDate = new Date();
  }
  // Convert the start and end dates to JavaScript Date objects.

  const d1 = new Date(date1);
  const d2 = new Date(newDate);

  if (d1 > d2) {
    [d1, d2] = [d2, d1]; // Swap the dates if d1 is later than d2
  }

  const delta = Math.abs(d2 - d1);
  const years = Math.floor(delta / (365 * 24 * 60 * 60 * 1000));

  const remainingDays = delta % (365 * 24 * 60 * 60 * 1000);
  const months = Math.floor(remainingDays / (30 * 24 * 60 * 60 * 1000));
  const days = Math.floor(
    (remainingDays % (30 * 24 * 60 * 60 * 1000)) / (24 * 60 * 60 * 1000)
  );

  const result = [];
  if (years > 0) {
    result.push(`${years} yr${years > 1 ? "s" : ""}`);
  }
  if (months > 0) {
    result.push(`${months} mo${months > 1 ? "s" : ""}`);
  }
  if (days > 0) {
    result.push(`${days} day${days > 1 ? "s" : ""}`);
  }

  return result.length > 0 ? result.join(" ") : "0 days";
}

export function getAge(sDate, eDate = "Present") {
  // console.log(sDate, eDate);
  let nDate = eDate;
  let diffTime;
  if (eDate === "Present") {
    diffTime = new Date() - new Date(sDate);
  } else {
    diffTime = new Date(nDate) - new Date(sDate);
  }
  console.log(diffTime);
  const totalDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  const years = Math.floor(totalDays / 365.25);
  const months = Math.floor((totalDays % 365.25) / 30.4375);
  console.log(totalDays);
  let yStr = "";
  let mStr = "";
  if (years > 0) {
    yStr = `${years} yr${years > 1 ? "s" : ""}`;
  }

  if (months > 0) {
    mStr = `${months} mo${months > 1 ? "s" : ""}`;
  }
  console.log(`${yStr} ${mStr}`);
  return `${yStr} ${mStr}`;
}

//function to check if birthday is today
export function isBDayToday(someDate) {
  const today = new Date();
  return (
    someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth()
  );
}

export function isValentinesDay() {
  const today = new Date();
  return today.getDate() === 14 && today.getMonth() === 1;
}
