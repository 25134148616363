import React from "react";
import WelcomePage1 from "./welcomePage1";
import WelcomePage2 from "./welcomePage2";

const DESIGN = 2;

function WelcomePage({ ...props }) {
  if (DESIGN === 1) {
    return <WelcomePage1 {...props} />;
  } else if (DESIGN === 2) {
    return <WelcomePage2 {...props} />;
  }
  return <WelcomePage1 {...props} />;
}

export default WelcomePage;
