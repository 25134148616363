import React from "react";
import loadable from "@loadable/component";
import CenterSpinner from "../../common/spinner";

function AsyncLoginForm({ ...rest }) {
  const AsyncLoad = loadable(() => import("../page/login"));
  return <AsyncLoad {...rest} fallback={<CenterSpinner />} />;
}

export default AsyncLoginForm;
