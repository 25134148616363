import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { getWebVersion } from "../../services/settings";

class ReloadModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
    };
  }

  doReload = () => {
    window.location.reload();
  };

  render() {
    let show = this.state.show;
    const webversion = getWebVersion();
    const version = this.props.version;
    if (webversion === version) {
      return null;
    } else {
      show = true;
    }
    console.log(`Latest version: ${version} Web Version: ${webversion}`);

    return (
      <Modal
        show={show}
        animation={false}
        centered
        backdrop="static"
        keyboard={false}
        // onHide={this.handleClose}
      >
        {/* <Modal.Header>
          <Modal.Title>
            <span className="text-primary">New Version Available!</span>
            </Modal.Title>
          </Modal.Header> */}
        <h4 className="text-primary pl-3 pt-3 pr-2">New Version Available!</h4>
        <Modal.Body>
          <div className="text-center mb-4">
            <span
              role="img"
              aria-label="celebrate"
              style={{ fontSize: "40px" }}>
              🎉
            </span>
            <br />
            Good News!
            <br />
            We have a new update for you!
          </div>
        </Modal.Body>
        {/* <Modal.Footer> */}
        <Button
          variant="primary"
          onClick={this.doReload}
          className="roundcornernocolorbutton mb-4 mx-4">
          Reload and Update
        </Button>
        {/* </Modal.Footer> */}
      </Modal>
      // <div className="appearslowly mb-0 p-2 text-center clickablebar alert-primary">
      //   <Button
      //     variant="danger"
      //     className="btn-lg px-4 roundcornernocolor"
      //     style={{ borderRadius: "50px" }}
      //     onClick={this.doReload}>
      //     {/* <div className="iconcircle float-left mt-1"> */}
      //     <TouchAppRoundedIcon
      //       fontSize="large"
      //       className="text-white"
      //       // style={{ marginTop: "-4px" }}
      //     />
      //     {/* </div> */}
      //     <div className="pl-2 float-right text-left">
      //       <div className="standardfontsize font-weight-bold">
      //         Click here to Update
      //       </div>
      //       <div style={{ marginTop: "-10px" }}>
      //         <span className="defaultstandardfontsize">{label}</span>
      //       </div>
      //     </div>
      //   </Button>
      // </div>
    );
  }
}

export default ReloadModal;
