import React, { useEffect, useState } from "react";
import Typist from "react-typist";

function SpreadWord() {
  const [isMounted, setIsMounted] = useState(true);

  useEffect(() => {
    return () => {
      setIsMounted(false);
    };
  }, []);

  return (
    <div>
      {isMounted && (
        <div className="font-weight-bold averagefontsize pt-4 pb-2 text-left">
          <Typist>
            <span role="img" aria-label="ship">
              🚢
            </span>{" "}
            Help spread the word
            <Typist.Backspace count={15} delay={1000} />
            <span>spread SeamanBio.PH </span>
            <Typist.Backspace count={20} delay={1000} />
            <span>share SeamanBio.PH </span>
            <Typist.Backspace count={20} delay={1000} />
            <span> our fellow Seafarers </span>
            <Typist.Backspace count={27} delay={1000} />
            <span> Sharing is caring! </span>
            <Typist.Backspace count={20} delay={1000} />
            <span> Create CV for FREE! </span>
            <Typist.Backspace count={20} delay={1000} />
            <span> Using only your Phone! </span>
            <Typist.Backspace count={24} delay={1000} />
            <span> No laptop required! </span>
          </Typist>
        </div>
      )}
    </div>
  );
}

export default SpreadWord;
