import React from "react";
import loadable from "@loadable/component";
import NavBarWithSpinner from "../../common/navBarwithSpinner";

function AsyncMainPage({ ...rest }) {
  const AsyncLoad = loadable(() => import("../page/mainPage"));
  return <AsyncLoad {...rest} fallback={<NavBarWithSpinner />} />;
}

export default AsyncMainPage;
