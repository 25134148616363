import React from "react";
import loadable from "@loadable/component";
import CenterSpinner from "../../common/spinner";

function AsyncViewApplications({ ...rest }) {
  // const AsyncLoad = loadable(() => import("../admin/postJobs"));
  const AsyncLoad = loadable(() => import("../admin/viewApplications"));
  return <AsyncLoad {...rest} fallback={<CenterSpinner />} />;
}

export default AsyncViewApplications;
