import React from "react";

const YoutubeEmbed = ({ title, youtubeid }) => {
  return (
    <React.Fragment>
      <h5 className="text-left mt-0 pt-0 pb-0">{title}</h5>
      <div
        className="video mb-2 shadow roundcornernocolorbutton"
        style={{
          position: "relative",
          paddingBottom: "56.25%" /* 16:9 */,
          paddingTop: "0px",
          height: 0,
        }}>
        <iframe
          src={`https://www.youtube.com/embed/${youtubeid}`}
          frameBorder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen
          title="video"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderRadius: "24px",
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default YoutubeEmbed;
