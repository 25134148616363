import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import DirectionsBoatIcon from "@material-ui/icons/DirectionsBoat";
// import Typist from "react-typist";
import Footer from "../../common/footer";
import "react-typist/dist/Typist.css";
import {
  getWebsiteFullDescription,
  getWebsiteName,
  getWebsitePrefix,
  getWebsiteSuffix,
} from "../../../services/settings";
import { getLatestVersion } from "../../../services/getversion";
import ShareSeamanBio from "../../share/shareSeamanBio";
import ReloadModal from "../../common/reloadModal";
import { getSiteSettingsDB } from "../../../services/config";
import StatCounter from "../../common/statCounter";
import ScrollToTop from "../../common/scrollToTop";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Modal } from "react-bootstrap";
import ShareIcon from "@material-ui/icons/Share";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Tagline from "../../common/tagline";
import { getCurrentUser } from "../../../services/user";
import { dump } from "../../../services/log";

const RespScreen = ({ ...props }) => {
  const userRef = useRef(null);
  const [settings, setSettings] = useState({
    members: "12,980",
    jobs: 6010,
    agencies: 350,
    applications: "155,133",
  });
  const [version, setVersion] = useState(null);
  const [showcopymodal, setShowCopyModal] = useState(false);
  const [text, setText] = useState("");
  const textStyle = "text-dark";
  const texttocopy = "https://seamanbio.ph";

  const params = props.match.params;
  let user = null;

  useEffect(() => {
    const getDatas = async () => {
      getLatestVersion().then((version) => {
        setVersion(version);
      });
      getSiteSettingsDB().then((setting) => {
        setSettings(setting);
      });
      //get params and save here
      user = (await getCurrentUser()) || null;
      console.log(user);
      const freq = params?.freq || "unknown";
      const email = params?.actor || "";
      let fullname = "guest";
      if (user) {
        userRef.current = user;
        fullname = user?.displayName || "";
      }
      //save here
      await dump(
        { userid: "", fullname: fullname, email: email },
        "emailfrequency",
        {
          freq: freq,
          notes: `${email} wants ${freq}`,
        }
      );
    };
    getDatas();
  }, [user]);

  const handleClose = () => {
    setShowCopyModal(false);
  };

  const handleCopy = () => {
    setShowCopyModal(true);
    setText("You may now share www.SeamanBio.ph with your friends 🚀");
  };
  let refid = null;
  if (params) {
    refid = params?.refid || "";
  }
  let el = document.querySelector("title");
  const title = `${getWebsiteName()}`;
  const content = getWebsiteFullDescription();
  el.innerText = title;
  el = document.querySelector("meta[name='description']");
  el.setAttribute("content", content);
  return (
    <div style={{ overflowX: "hidden" }}>
      {version && <ReloadModal version={version} />}

      <div className="container col-lg mt-0 pt-0">
        <div className="row align-items-center d-flex pt-4 mt-4">
          <div className="text-center container h-50 pb-0 mb-0">
            <DirectionsBoatIcon className="logoborder logo-big text-white p-2" />
            <h1 className="mb-0 pb-0 font-weight-bold text-primary pointer">
              {getWebsitePrefix()}
              <span className="text-muted font-weight-normal">
                {getWebsiteSuffix()}
              </span>
            </h1>
            <div className="text-center font-weight-bold defaultfontsize">
              <Tagline />
            </div>
            <div
              className="mt-3 mb-0 py-5 roundcornernocolorbutton alert alert-primary"
              style={{ border: "4px dashed #007bff" }}>
              <h1>THANK YOU!</h1>
            </div>

            {!userRef.current ? (
              <div style={{ width: "280px" }} className="mx-auto text-center">
                <div className="pt-4">
                  <p className="font-weight-bolds text-center text-muted mb-2 mt-0">
                    No account?
                  </p>
                  <Link
                    className="font-weight-bold btn-success btn-lg btn-block px-4 mb-4 roundcornernocolorbutton"
                    to={`/register/${refid}`}>
                    Create FREE CV
                  </Link>
                </div>
                <div className="mt-4 mb-3">
                  <p className="font-weight-bolds text-center text-muted mb-2 mt-0">
                    Already have an account?
                  </p>
                  <Link
                    style={{ border: "2px dashed #007bff" }}
                    className="font-weight-bold btn btn-outline-primary btn-lg btn-block px-4 mb-0 roundcornernocolorbutton"
                    to={`/login/${refid}`}>
                    Log In
                  </Link>
                </div>
                <div className="mt-4 mb-0">
                  <Link
                    className="text-primary normalsmallfontsize btn-lg btn-block px-4 mb-0 roundcornernocolorbutton"
                    to={`/forgot/${refid}`}>
                    <u>Forgot your password?</u>
                  </Link>
                </div>
              </div>
            ) : (
              <div>
                <Link
                  style={{ border: "2px dashed #007bff" }}
                  className="mt-5 font-weight-bold btn btn-outline-primary btn-lg btn-block px-4 mb-0 roundcornernocolorbutton"
                  to={`/home`}>
                  Back to Home
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="text-center">
        <div className="pt-5">
          <div className="pb-4 mb-0 bg-white">
            <hr style={{ borderTop: "6px dashed #cde6fe" }} />
          </div>
        </div>
        <div className="bg-white">
          {settings && (
            <div className="pt-2 pb-2">
              <StatCounter settings={settings} />
            </div>
          )}
        </div>
      </div>

      <div className="container col-lg-6">
        <div className="container">
          <div className="my-4 pb-2">
            <p className="font-weight-bold averagefontsize pt-0 pb-2 text-left">
              Please help us spread SeamanBio.PH{" "}
              <span role="img" aria-label="point">
                👇
              </span>
            </p>

            <div className="py-4 text-center d-flex justify-content-around alert alert-primary roundcornernocolor">
              <ShareSeamanBio
                title={"Share SeamanBio.ph"}
                text={"https://seamanbio.ph"}
                item={null}
              />
              <CopyToClipboard text={texttocopy} onCopy={() => handleCopy()}>
                <Button
                  variant={`${textStyle}`}
                  className="float-right btn-sm py-2 pl-2 pr-3">
                  <div className="float-left" style={{ width: "25px" }}>
                    <ShareIcon
                      className="text-primary"
                      style={{
                        fontSize: "18px",
                        marginTop: "-2px",
                      }}
                    />
                  </div>
                  <div className="float-left">Share</div>
                </Button>
              </CopyToClipboard>
            </div>
            <div className="text-center">
              <ScrollToTop />
            </div>

            <Footer />
            <Modal
              show={showcopymodal}
              onHide={handleClose}
              animation={false}
              centered
              backdrop="static"
              keyboard={true}>
              <Modal.Header style={{ border: "none" }}>
                <Modal.Title className="text-dark font-weight-bold">
                  Link Copied
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="mt-0 mb-4 text-center">
                <CheckCircleIcon
                  className="text-success"
                  style={{ fontSize: "50px" }}
                />
                <br />
                <br />
                {text}
              </Modal.Body>
              <Modal.Footer style={{ border: "none" }}>
                <Button
                  variant="link"
                  className="forcenoline noline mr-2"
                  onClick={handleClose}>
                  <span className="text-muted forcenoline noline">Got it</span>
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RespScreen;
