import React from "react";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import { Link } from "react-router-dom";

function CustomBack({ link = "/welcome", text = "Back" }) {
  return (
    <div className="row justify-content-center mx-auto">
      <div className="col-lg-6">
        <main className="container">
          <div className="pb-2 pointer text-left ">
            <Link
              to={link}
              style={{ textDecoration: "none" }}
              className="my-2 text-primary d-flex align-items-center justify-content-start">
              <ArrowBackIosRoundedIcon fontSize="small" className=" mr-2" />
              <span className="text-dark">{text}</span>
            </Link>
          </div>
        </main>
      </div>
    </div>
  );
}

export default CustomBack;
