import { isResumeComplete } from "../utils/profile";
import { getMassDateTime } from "../utils/utils";
import fire from "./fire";

export async function logEvent(event, data) {
  fire.analytics().logEvent(event, data);
}

export async function logAction(user, actionType, actionObj) {
  const logRef = fire.firestore().collection("actions").doc();
  const uniqNum = new Date().getTime();
  const complete = isResumeComplete(user);
  const docData = {
    logId: user.userid,
    logCVComplete: complete,
    logName: user?.fullname,
    logActor: user.email,
    logSource: actionType,
    logTimestamp: uniqNum,
    logCreated: new Date().toISOString(),
    ...actionObj,
  };

  await logRef.set(docData);
}
export async function logMessage(where, data) {
  const logMessage = fire.functions("asia-east1").httpsCallable("logMessage");
  console.error(
    `logMessage Source: ${where} Email: ${data.email} Code: ${data.code} Message: ${data.message}`
  );
  const date = new Date(); //.toDateString()
  logMessage({
    email: data.email,
    code: data.code,
    message: data.message,
    where: where,
    timestamp: `${date.toDateString()} ${getMassDateTime(date)}`,
  });
}

export async function dump(user, actionType, actionObj) {
  console.log(user, actionType, actionObj);
  const logRef = fire.firestore().collection("dump").doc();
  const uniqNum = new Date().getTime();
  const docData = {
    logId: user.userid,
    logName: user?.fullname,
    logActor: user.email,
    logSource: actionType,
    logTimestamp: uniqNum,
    logCreated: new Date().toISOString(),
    ...actionObj,
  };

  await logRef.set(docData);
}
