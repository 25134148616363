import { getDaysInMilliseconds } from "../utils/date";

/*
 * These config settings can be placed in separate config page
 */
export function getAllowedBookingCurrentDates() {
  return ["oct2020", "aug2020", "sep2020", "jul2020"];
}

export function getAllowedCurrentDates() {
  return ["sep2020", "oct2020"];
}

export function getJulyMonth() {
  return "jul";
}

export function getAugustMonth() {
  return "aug";
}

export function getPreviousMonth() {
  return "sep";
}

export function getNextMonth() {
  return "oct";
}

export function getJulyBookingLabel() {
  return "July Booking";
}

export function getAugustBookingLabel() {
  return "August Booking";
}

export function getPreviousMonthBookingLabel() {
  return "September Booking";
}

export function getNextMonthBookingLabel() {
  return "October Booking";
}

export function getNextMonthMasstoUpload() {
  return "oct2020";
}

export function getNextMonthDate() {
  return "October 1, 2020";
}

export function getNextFullMonth() {
  let nextmonth = getNextMonthDate().split(" ");
  return nextmonth[0];
}

export function getCutOffTime() {
  //48 hours
  return 48;
}

export function enableCancelMassBooking() {
  return true;
}

export function enableEditBasicProfile() {
  return true;
}

export function enableFirstBookingReminderAdmin() {
  return false;
}

export function disableBooking() {
  return false;
}

export function enableProfilePhotoUpload() {
  return true;
}

export function enableVerifyEmail() {
  return false;
}

export function getMaxSeaExpLimit() {
  return 20;
}

export function enableTravelsForAll(enable = false) {
  const ENABLE = true;
  if (ENABLE) {
    return true;
  }
  return enable;
}

export function getWebsiteName() {
  return process.env.REACT_APP_WEBSITE_NAME;
}

export function getWebsiteDescription() {
  return process.env.REACT_APP_WEBSITE_DESCRIPTION;
}

export function getWebsiteFullDescription() {
  return process.env.REACT_APP_WEBSITE_FULL_DESCRIPTION;
}

export function getWebsitePrefix() {
  return process.env.REACT_APP_WEBSITE_NAME_PREFIX;
}

export function getWebsiteSuffix() {
  return process.env.REACT_APP_WEBSITE_NAME_SUFFIX;
}

export function getWebsiteDomain() {
  return process.env.REACT_APP_WEBSITE_DOMAIN;
}

export function getWebsiteURL() {
  return process.env.REACT_APP_WEBSITE_URL;
}

export function isAllowNoTravelDocsTrainings() {
  return true; //true means allow for users to apply even if they don't have any travel docs or trainings
}

export function isGlobalPublicPageEnable() {
  return true; //true means allow to view /magsaysay or /lydiamar public page
}

export function isAgencyAdminEnable() {
  return true; //true means allow for admin to view /magsaysay or /lydiamar admin page
}

export function isSponsoredEnable() {
  return true; //true means allow for users to apply or show magsaysay-related features like banners
}

export function isPDFCVUploadEnable(config) {
  return config?.pdfcvupload === true;
}

export function isAgencyDemoEnable() {
  return false;
}

export function isLiveEmailEnable() {
  return true;
}

export function getLastEmailThreshold() {
  return getDaysInMilliseconds() * getLastEmailThresholdInDays(); //7 days
}

export function getLastEmailThresholdInDays() {
  return 3; //7 days
}

export function getWebVersion() {
  return "0.9.257"; //To force all users to reload
}
