import React, { Component } from "react";

class ScrollToTop extends Component {
  handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  render() {
    return (
      <button
        className="py-2 px-3 btn btn-sm btn-link mt-5 roundcornernocolorbutton bg-white text-center font-weight-bold"
        style={{ border: "1px solid #dddddd" }}
        onClick={this.handleClick}>
        Scroll to top
      </button>
    );
  }
}

export default ScrollToTop;
