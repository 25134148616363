import React from "react";
import loadable from "@loadable/component";
import CenterSpinner from "../../common/spinner";

function AsyncRolesSetup({ ...rest }) {
  const AsyncLoad = loadable(() => import("../page/rolesDashboard"));
  return <AsyncLoad {...rest} fallback={<CenterSpinner />} />;
}

export default AsyncRolesSetup;
