import React from "react";
import Joi from "joi-browser";
import Form from "../../common/form";
import fire from "../../../services/fire";
import LoadingSpinner from "../../common/loadingSpinner";
import SystemLogo from "../../blocks/systemLogoBlock";
import {
  createWithEmailAndPassword,
  isUserLoggedIn,
} from "../../../services/user";
import { dump, logEvent, logMessage } from "../../../services/log";
import { isValidFullname } from "../../../services/validation";
import { Link } from "react-router-dom";
import countryList from "react-select-country-list";
import Footer from "../../common/footer";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import {
  getWebsiteFullDescription,
  getWebsiteName,
} from "../../../services/settings";
import { getLatestVersion } from "../../../services/getversion";
import CustomBack from "../../common/customBack";
import ReloadModal from "../../common/reloadModal";
import { isReservedWord } from "../service/user";
import { Button, Media, Modal } from "react-bootstrap";
import RefreshIcon from "@material-ui/icons/Refresh";
import BasicMediaDetails from "../../common/basicMediaDetails";
const validator = require("email-validator");

class RegisterForm extends Form {
  state = {
    countries: countryList()
      .getData()
      .map((item) => {
        return { _id: item.value, name: item.label };
      }),
    showpass: false,
    showmodal: false,
    data: {
      fullname: "",
      userhandle: "",
      email: "",
      password: "",
      confirmpassword: "",
      country: "",
      bio: "",
    },
    refid: this.props?.match?.params?.refid || "",
    errors: {},
    loading: true,
    version: null,
    emailaccountcreationfailed: false,
    accountcreateerror:
      "Something went wrong. Please try to login, reset your password or use another account.",
    unrecoverableerror: "We are unable to create your account.",
    emailsenterror:
      "There was a problem sending to your email account. Please try to reset your password or use another account.",
  };

  schema = {
    fullname: Joi.string().trim().required().label("Fullname"),
    userhandle: Joi.string()
      .trim()
      .min(3)
      .regex(/^([a-zA-Z0-9_-]+)$/)
      .required()
      .label("Username"),
    email: Joi.string().trim().email().required().label("Email"),
    country: Joi.string().trim().required().label("Country"),
    bio: Joi.string().trim().required().label("Position or Rank"),
    password: Joi.string().trim().required().min(8).label("Password"),
    confirmpassword: Joi.string()
      .trim()
      .required()
      .min(8)
      .label("Confirm Password"),
  };

  doSubmit = async () => {
    console.log("Submit");
    const { data, errors, refid } = this.state;

    //trim spaces
    let fullname = data.fullname.trim();
    let email = data.email.toLowerCase().trim();
    let password = data.password.trim();
    let confirmpassword = data.confirmpassword.trim();
    let userhandle = data.userhandle.trim().toLowerCase();
    let country = data.country.trim();
    let headline = data.bio.trim();
    let error;

    if (!validator.validate(email)) {
      error = { email: "Invalid Email" };
      this.setState({ errors: error, loading: false });
      return;
    }
    console.log(password, confirmpassword);
    if (isReservedWord(userhandle)) {
      error = { userhandle: "Please use another Username." };
      this.setState({ errors: error, loading: false });
      return;
    }
    const result = userhandle.match(/^([a-zA-Z0-9_-]+)$/);
    // console.log(result);
    let isvalidfullname = isValidFullname(fullname);
    if (!result) {
      // "Min 3 characters. Only dash, underline and alphanumeric characters allowed.",
      const msg =
        "Minimum 3 characters required. Only use A-Z, a-z, 0-9, '-' and '_'";
      error = { userhandle: msg };
      this.setState({ errors: error, loading: false });
      return;
    }
    //check if firstname and last name are empty
    if (fullname === "" || !isvalidfullname) {
      const msg = fullname === "" ? "Please provide Name" : "Valid Name only.";
      error = { fullname: msg };
      this.setState({ data, errors: error, loading: false });
      return;
    }
    console.log(password, confirmpassword);
    //check if emails match
    if (password !== confirmpassword) {
      error = { confirmpassword: "Passwords do not match." };
      this.setState({ data, errors: error, loading: false });
      return;
    }

    const user = await fire
      .firestore()
      .collection("users")
      .where("userhandle", "==", userhandle)
      .get();

    const list = [];
    user.forEach((doc) => {
      list.push(doc.data());
    });

    if (list.length) {
      const msg = `Someone has already used ${userhandle}. Please use another Username.`;
      error = { userhandle: msg };
      this.setState({ data, errors: error, loading: false });
      return;
    }

    this.setState({
      data: {
        email,
        fullname,
        userhandle: userhandle?.trim(),
        password,
        confirmpassword,
        country,
        headline,
      },
      errors,
      loading: true,
    });

    // Call the server
    try {
      let result = await createWithEmailAndPassword(email, password);
      console.log(result);
      await logMessage("create_email_and_password_logging", {
        email: email,
        message:
          "Successful after createWithEmailAndPassword" || result?.message,
        code: result?.code,
      });
      if (result && result.code === "success") {
        logEvent("create_email_password_success", { email: email });
        const user = result.user;
        if (!user) {
          await logMessage("create_email_and_password_success_but_null", {
            email: email,
            message: "Success but user is NOT!",
            code: result?.code,
          });
        }
        let userid = user.uid;
        await user.updateProfile({ displayName: fullname });

        try {
          const uniqNum = new Date().getTime();
          // console.log("Writing to users collection ");
          //Log to user DB and indicate email verified: false
          await fire.firestore().doc(`users/${userid}`).set({
            emailContactable: false,
            email,
            fullname,
            country,
            bio: headline,
            //default: light blue
            background: 7,
            created: uniqNum,
            userid: userid,
            userhandle: userhandle?.trim(),
            edu: [],
            exp: [],
            lastupdated: new Date().getTime(),
          });

          console.log("User created...");
          logEvent("user_account_create_success", { email: email });
          let link = `/home`;
          // if (refid) {
          //   link = `/a/${refid}`;
          // }
          console.log("Redirect to home....");
          this.props.history.push(link);
          return;
          // console.log("Before sending email verification");
          // try {
          //   // await user.sendEmailVerification();
          //   // console.log("Done sending email verification");
          //   // logEvent("auto_send_email_verification_success", {
          //   //   email: email,
          //   // });
          //   //Route /home will check if email is verified, if not, show Account Verify page
          //   // let link = `/welcome`;
          //   let link = `/home`;
          //   // if (refid) {
          //   //   link = `/a/${refid}`;
          //   // }
          //   console.log("Redirect to home....");
          //   this.props.history.push(link);
          // } catch (error) {
          //   logEvent("auto_send_email_verification_fail", {
          //     email: email,
          //     message: error.message,
          //   });
          //   console.error("Error sending email: ", error);

          //   this.setState({
          //     emailaccountcreationfailed: true,
          //     loading: false,
          //     errors: { email: this.state.emailsenterror },
          //   });
          // }
        } catch (ex) {
          console.error("Error creating email and password: ", ex);
          logEvent("user_account_create_fail", {
            email: email,
            message: ex.message,
          });
          await logMessage("create_email_and_password_exception_fail", {
            email: email,
            message: ex.message,
            code: ex.code,
          });
          this.setState({
            emailaccountcreationfailed: true,
            loading: false,
            errors: { email: this.state.accountcreateerror },
          });
        }
      } else {
        logEvent("create_email_password_fail", {
          email: email,
          message: result.message,
        });
        let showModal = false;
        if (result?.intercept === true) {
          await dump(
            { userid: "", fullname: fullname, email: email },
            "register",
            {
              code: result.code || "",
              message: result.message || "",
              result: result,
              notes: "createWithEmailAndPassword has unknown error",
            }
          );
          showModal = true;
        }
        await logMessage("create_email_and_password_fail", {
          email: email,
          message: result.message,
          code: result.code,
        });
        console.log("Error:", result);
        this.setState({
          showmodal: showModal,
          emailaccountcreationfailed: true,
          loading: false,
          errors: result,
        });
      }
    } catch (ex) {
      console.log("Errors:", ex);

      logEvent("create_email_password_exception_fail", {
        email: email,
        message: ex.message,
      });
      // await dump({ userid: "", fullname: fullname, email: email }, "register", {
      //   code: ex.code || "",
      //   message: ex.message || "",
      //   result: ex,
      //   notes: "createWithEmailAndPassword throws exception",
      // });
      await logMessage("create_email_and_password_exception_fail", {
        email: email,
        message: ex.message,
        code: ex.code,
      });
      if (ex.hasOwnProperty("code")) {
        console.log("Errors:", ex);
        let errors;
        if (ex.code === "auth/too-many-requests") {
          errors = { email: ex.message };
        } else {
          errors = {
            email: ex.message,
          };
        }
        this.setState({
          loading: false,
          errors,
        });
      } else {
        this.setState({
          loading: false,
          errors: { email: this.state.unrecoverableerror },
        });
      }
    }
  };

  async componentDidMount() {
    const loggedIn = await isUserLoggedIn();
    // console.log("Logged in: ", loggedIn);
    let el = document.querySelector("title");
    const title = `${getWebsiteName()}`;
    const content = getWebsiteFullDescription();
    el.innerText = title;
    el = document.querySelector("meta[name='description']");
    el.setAttribute("content", content);

    // const countries = countryList()
    //   .getData()
    //   .map((item) => {
    //     return { _id: item.value, name: item.label };
    //   });
    if (loggedIn) {
      // console.log("Logged in");
      this.props.history.push("/home");
      return;
    }
    getLatestVersion().then((version) => {
      this.setState({
        // countries,
        version,
      });
    });
    this.setState({
      loading: false,
    });
    window.scrollTo(0, 0);
  }

  handleSelectCountry = (code) => {
    this.setState({ country: code });
  };

  render() {
    const { loading, countries, refid, version } = this.state;

    if (loading === true) {
      return <LoadingSpinner />;
    }

    return (
      <React.Fragment>
        {version && <ReloadModal version={version} />}

        <div className="my-4 pt-0"></div>
        <CustomBack text="Home" link="/welcome" />

        <SystemLogo refid={refid} />
        <div className="row justify-content-center  mx-auto">
          <div className="col-lg-6">
            <main className="container">
              <div className="pb-3 pt-2">
                <h4 className="pb-0 mb-0">Join Now</h4>
                <p className="text-left text-muted mb-4">
                  It's quick and easy. No laptop required.
                </p>
                <form onSubmit={this.handleSubmit}>
                  {this.renderInput(
                    "fullname",
                    "Fullname",
                    "text",
                    "Your Fullname"
                  )}
                  {this.renderInput(
                    "userhandle",
                    "Username",
                    "text",
                    "Ex. donaldcris"
                  )}
                  {this.renderInput(
                    "bio",
                    "Position or Rank",
                    "text",
                    "ex. 2nd Officer w/Master License"
                  )}
                  {this.renderSelect("country", "Country", countries)}

                  {/* <ReactFlagsSelect
                    selected={country}
                    onSelect={(code) => this.handleSelectCountry(code)}
                    placeholder="Select your Country"
                    className="averagefontsize"
                    id="country"
                  /> */}
                  {/* {this.renderSelect("country", "Your country", countries)} */}

                  {this.renderInput(
                    "email",
                    `Email ${this.state.data.email}`,
                    "text",
                    "Your email"
                  )}
                  <div className="px-2  alert alert-danger">
                    <Media className="mb-1">
                      <span className="mr-1" role="img" aria-label="caution">
                        🔔
                      </span>
                      <Media.Body className="standardfontsize">
                        When you apply & shortlisted, Agencies will contact you
                        via email. Make sure your EMAIL is VALID and your INBOX
                        is NOT FULL!
                      </Media.Body>
                    </Media>
                  </div>
                  {this.renderInput(
                    "password",
                    "Password",
                    this.state.showpass ? "text" : "password",
                    "8 characters or more"
                  )}

                  <div
                    onClick={() =>
                      this.setState({ showpass: !this.state.showpass })
                    }
                    className="float-right pointer btn-link mb-4"
                    style={{ marginTop: "-15px" }}>
                    {this.state.showpass === false ? (
                      <>
                        <VisibilityOutlinedIcon /> Show Password
                      </>
                    ) : (
                      <>
                        <VisibilityOffOutlinedIcon /> Hide Password
                      </>
                    )}
                  </div>
                  {this.renderInput(
                    "confirmpassword",
                    "Confirm Password",
                    this.state.showpass ? "text" : "password",
                    "8 characters or more"
                  )}
                  <div className="mt-4 mb-4">
                    <p>By clicking Join Now:</p>
                    <BasicMediaDetails
                      lead={<>✅</>}
                      value="You can create and download FREE CV"
                    />
                    <BasicMediaDetails
                      lead={<>✅</>}
                      value="You can Apply Direct to DMW-listed Agencies"
                    />
                    <BasicMediaDetails
                      lead={<>✅</>}
                      value="You can Apply Jobs by Rank"
                    />
                    <BasicMediaDetails
                      lead={<>✅</>}
                      value="You can receive Job Alerts"
                    />
                    <BasicMediaDetails
                      lead={<>✅</>}
                      value="When you apply & shortlisted, Agencies will contact you"
                    />
                  </div>
                  {!loading && this.renderBlockButton("Join Now")}
                  {loading && this.renderLoadingBlockButton("Please wait...")}
                </form>
              </div>

              <div className="pt-3 pb-4 text-center">
                <p className="averagefontsize">
                  Already have an account?{" "}
                  <Link
                    className=" text-danger font-weight-bold"
                    to={`/login/${refid}`}>
                    Log in
                  </Link>
                </p>
              </div>
              <div className="my-4 py-4">
                <hr />
              </div>
              <Footer />
            </main>
          </div>
        </div>
        <Modal
          show={this.state.showmodal}
          onHide={() => this.setState({ showmodal: false })}
          animation={false}
          centered
          backdrop="static"
          keyboard={true}>
          <Modal.Body className="my-4 text-center">
            <RefreshIcon
              className="text-primary"
              style={{ fontSize: "100px" }}
            />
            <h4 className="text-dark">Please reload page.</h4>
          </Modal.Body>
          <Modal.Footer style={{ border: "none" }}>
            <Button
              variant="primary"
              className="forcenoline noline mr-2 roundcornernocolorbutton"
              onClick={() => window.location.reload()}>
              <span className="">Reload</span>
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default RegisterForm;
