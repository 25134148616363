import React, { Component } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import fire from "./services/fire";
import AsyncHome from "./core/asyncHome";
import LoadingSpinner from "./core/common/loadingSpinner";
// import AsyncRegisterForm from "./core/account/async/asyncRegister";
import AsyncLoginForm from "./core/account/async/asyncLogin";
// import AsyncAccountSetup from "./core/account/async/asyncAccountSetup";
import AsyncForgotPassword from "./core/account/async/asyncForgot";
import AsyncRegisterSuccess from "./core/account/async/asyncRegsiterSuccess";
import AsyncAuthHandler from "./core/account/async/asyncAuthHandler";
import AsyncProfile from "./core/account/async/asyncProfile";
import AsyncMainPage from "./core/account/async/asynMainPage";
import AsyncAccountEdit from "./core/account/async/asyncAccountEdit";
import ProtectedRoute from "./core/route/protectedRoute";
import WelcomePage from "./core/welcome/page/welcomePage";
import AsyncRolesSetup from "./core/roles/async/asyncRolesSetup";
import AsyncUserList from "./core/admin/async/asyncUserList";
import AsyncAdminDashboard from "./core/admin/async/asyncAdminDashboard";
import AsyncPageNotFound from "./core/staticpages/async/asyncPageNotFound";
import AsyncInvalidUserList from "./core/admin/async/asyncInvalidUserList";
import RegisterForm from "./core/account/page/register";
import AsyncAgencyList from "./core/agencies/async/asyncProfile";
import AsyncPost from "./core/quickpost/async/asyncPost";
import AsyncPostJobs from "./core/quickpost/async/asyncPostJobs";
import AsyncViewAllPosts from "./core/quickpost/async/asyncViewAllPosts";
import AsyncViewAllJobPosts from "./core/quickpost/async/asyncViewAllJobPosts";
import AsyncTravel from "./core/travel/async/asyncTravel";
import AsyncTestimonials from "./core/testimonials/async/asyncTestimonials";
import AsyncFBGroups from "./core/admin/async/asyncFBGroups";
import AsyncUserStats from "./core/stats/async/asyncUserStats";
import AsyncPrivacy from "./core/privacy/async/asyncPrivacy";
import AsyncViewAgencyAllJobPosts from "./core/quickpost/async/asyncViewAgencyAllJobPosts";
import AsyncViewApplications from "./core/quickpost/async/asyncViewApplications";
import AsyncDashboard from "./core/dashboard/async/asyncDashboard";
import { isGlobalPublicPageEnable } from "./services/settings";
// import AsyncSearch from "./core/search/async/asyncSearch";
import AsyncUploadCV from "./core/uploadcv/async/asyncUploadCV";
import RespScreen from "./core/resp/page/respScreen";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      loading: true,
    };
  }

  componentDidMount() {
    this.authListener();
  }

  authListener() {
    fire.auth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({ user, loading: false });
      } else {
        this.setState({ user: null, loading: false });
      }
    });
  }

  render() {
    const { user, loading } = this.state;

    if (loading === true) {
      return <LoadingSpinner />;
    }

    return (
      <React.Fragment>
        <Switch>
          {/*
          NO LOGIN REQUIRED
          */}
          <Route
            path="/privacy"
            render={(props) => {
              return <AsyncPrivacy {...props} user={user} />;
            }}
          />
          <Route
            path="/welcome/:refid"
            render={(props) => {
              if (!user) {
                return <WelcomePage {...props} user={user} />;
              }
              return <AsyncHome {...props} user={user} />;
            }}
          />

          <Route
            path="/resp/:freq/:actor"
            render={(props) => {
              return <RespScreen {...props} user={user} />;
            }}
          />
          <Route
            path="/welcome"
            render={(props) => {
              if (!user) {
                return <WelcomePage {...props} user={user} />;
              }
              return <AsyncHome {...props} user={user} />;
            }}
          />

          <Route
            path="/auth"
            render={(props) => {
              return <AsyncAuthHandler {...props} user={user} />;
            }}
          />
          <Route
            path="/register/:refid"
            render={(props) => {
              // if (user) return <Redirect to="/home" />;
              return <RegisterForm {...props} user={user} />;
              // return <AsyncRegisterForm {...props} user={user} />;
            }}
          />
          <Route
            path="/register"
            render={(props) => {
              // if (user) return <Redirect to="/home" />;
              return <RegisterForm {...props} user={user} />;
              // return <AsyncRegisterForm {...props} user={user} />;
            }}
          />
          <Route
            path="/testimonials"
            render={(props) => {
              // if (user) return <Redirect to="/home" />;
              return <AsyncTestimonials {...props} user={user} />;
              // return <AsyncRegisterForm {...props} user={user} />;
            }}
          />
          {/*  
          <Route
            path="/magsaysay"
            render={(props) => {
              // if (user) return <Redirect to="/home" />;
              if (!isGlobalPublicPageEnable())
                return <Redirect to="/home" />;
              return <AsyncViewAgencyAllJobPosts {...props} user={user} />;
              // return <AsyncRegisterForm {...props} user={user} />;
            }}
          /> */}
          <Route
            path="/forgot/:refid"
            render={(props) => {
              if (user) {
                return <Redirect to="/home" />;
              }
              return <AsyncForgotPassword {...props} user={user} />;
            }}
          />
          <Route
            path="/forgot"
            render={(props) => {
              if (user) {
                return <Redirect to="/home" />;
              }
              return <AsyncForgotPassword {...props} user={user} />;
            }}
          />
          <Route
            path="/login/:refid"
            render={(props) => {
              if (user) {
                return <Redirect to="/home" />;
              }
              return <AsyncLoginForm {...props} user={user} />;
            }}
          />
          <Route
            path="/login"
            render={(props) => {
              if (user) {
                return <Redirect to="/home" />;
              }
              return <AsyncLoginForm {...props} user={user} />;
            }}
          />

          <Route
            path="/account/created"
            render={(props) => {
              if (!user) {
                return <Redirect to="/welcome" />;
              }
              return <AsyncRegisterSuccess {...props} user={user} />;
            }}
          />
          {/* <Route
            path="/account/setup"
            render={(props) => {
              if (!user) {
                return <Redirect to="/welcome" />;
              }
              return <AsyncAccountSetup {...props} user={user} />;
            }}
          /> */}
          <Route
            path="/account/edit"
            render={(props) => {
              if (!user) {
                return <Redirect to="/welcome" />;
              }
              return <AsyncAccountEdit {...props} user={user} />;
            }}
          />

          <Route
            path="/profile"
            render={(props) => {
              if (!user) {
                return <Redirect to="/welcome" />;
              }
              // else {
              //   if (!user.emailVerified) {
              //     return <AccountVerify {...props} user={user} />;
              //   }
              // }
              return <AsyncProfile {...props} user={user} />;
            }}
          />
          <Route
            path="/upload"
            render={(props) => {
              if (!user) {
                return <Redirect to="/welcome" />;
              }
              return <AsyncUploadCV {...props} user={user} />;
            }}
          />
          {/* <ProtectedRoute component={AsyncMainPage} path="/home" user={user} /> */}

          <Route
            path="/agencies"
            render={(props) => {
              if (!user) {
                return <Redirect to="/welcome" />;
              }
              // else {
              //   if (!user.emailVerified) {
              //     return <AccountVerify {...props} user={user} />;
              //   }
              // }
              return <AsyncAgencyList {...props} user={user} />;
            }}
          />
          {/* <Route
            path="/search"
            render={(props) => {
              if (!user) {
                return <Redirect to="/welcome" />;
              }
              // else {
              //   if (!user.emailVerified) {
              //     return <AccountVerify {...props} user={user} />;
              //   }
              // }
              return <AsyncSearch {...props} user={user} />;
            }}
          /> */}
          <Route
            path="/ctrl/roles"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                // if (!user.emailVerified) {
                //   return <AccountVerify {...props} user={user} />;
                // }
              }
              return <AsyncRolesSetup {...props} user={user} />;
            }}
          />
          <Route
            path="/ctrl/fbgroups"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                // if (!user.emailVerified) {
                //   return <AccountVerify {...props} user={user} />;
                // }
              }
              return <AsyncFBGroups {...props} user={user} />;
            }}
          />
          <Route
            path="/ctrl/userlist"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                // if (!user.emailVerified) {
                //   return <AccountVerify {...props} user={user} />;
                // }
              }
              return <AsyncUserList {...props} user={user} />;
            }}
          />
          <Route
            path="/ctrl/userstats"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                // if (!user.emailVerified) {
                //   return <AccountVerify {...props} user={user} />;
                // }
              }
              return <AsyncUserStats {...props} user={user} />;
            }}
          />
          <Route
            path="/ctrl/invalidusers"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                // if (!user.emailVerified) {
                //   return <AccountVerify {...props} user={user} />;
                // }
              }
              return <AsyncInvalidUserList {...props} user={user} />;
            }}
          />
          <ProtectedRoute
            component={AsyncViewAllPosts}
            path="/jobposts/:selectedrank"
            user={user}
          />
          <ProtectedRoute
            component={AsyncViewAllPosts}
            path="/jobposts"
            user={user}
          />
          <ProtectedRoute
            component={AsyncViewAllJobPosts}
            path="/jobs"
            user={user}
          />
          <ProtectedRoute
            component={AsyncDashboard}
            path="/dashboard"
            user={user}
          />
          <ProtectedRoute
            component={AsyncPost}
            path="/ctrl/jobpost"
            user={user}
          />
          <ProtectedRoute
            component={AsyncPostJobs}
            path="/ctrl/postjobs"
            user={user}
          />
          <ProtectedRoute
            component={AsyncViewApplications}
            path="/ctrl/viewapplications/:jobid"
            user={user}
          />
          <Route
            path="/ctrl"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                // if (!user.emailVerified) {
                //   return <AccountVerify {...props} user={user} />;
                // }
              }
              return <AsyncAdminDashboard {...props} user={user} />;
            }}
          />

          <ProtectedRoute component={AsyncMainPage} path="/home" user={user} />
          {/* <ProtectedRoute
            component={AsyncUserProfile}
            path="/home"
            user={user}
          /> */}
          <ProtectedRoute component={AsyncTravel} path="/travels" user={user} />

          <Route
            path="/:agency"
            render={(props) => {
              // if (user) return <Redirect to="/home" />;
              if (!isGlobalPublicPageEnable()) return <Redirect to="/home" />;
              return <AsyncViewAgencyAllJobPosts {...props} user={user} />;
              // return <AsyncRegisterForm {...props} user={user} />;
            }}
          />
          <Redirect from="/" exact to="/welcome" />
          <Route
            path="*"
            render={(props) => {
              if (!user) {
                return <AsyncPageNotFound />;
              }
              return <AsyncPageNotFound {...props} user={user} />;
            }}
          />
          <Route
            path="/404"
            render={(props) => {
              if (!user) {
                return <AsyncPageNotFound />;
              }
              return <AsyncPageNotFound {...props} user={user} />;
            }}
          />
        </Switch>
        <div className="clearfix my-4">&nbsp;</div>
      </React.Fragment>
    );
  }
}

export default App;
