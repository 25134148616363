import React from "react";
import loadable from "@loadable/component";
import CenterSpinner from "../../common/spinner";

function AsyncUserStats({ ...rest }) {
  const AsyncLoad = loadable(() => import("../page/userStats"));
  return <AsyncLoad {...rest} fallback={<CenterSpinner />} />;
}

export default AsyncUserStats;
