import React from "react";
// import Typist from "react-typist";
import { getWebsiteDescription } from "../../services/settings";

function Tagline() {
  return (
    <div className="font-weight-bold averagefontsize pt-0 pb-2 text-center">
      {/* <Typist> */}
      {/* <span>{getWebsiteDescription()}</span>
        <Typist.Backspace count={31} delay={1000} /> */}
      <span>{getWebsiteDescription()}</span>
      {/* <Typist.Backspace count={33} delay={1000} />
        <span>{getWebsiteDescription()}</span>
        <Typist.Backspace count={31} delay={1000} />
        <span>Save Money. Build FREE Resume/CV.</span> */}
      {/* </Typist> */}
    </div>
  );
}

export default Tagline;
