import React from "react";
import { Media } from "react-bootstrap";

function BasicMediaDetails({ lead, value }) {
  return (
    <Media className="">
      <b className="mr-1">{lead}</b>
      <Media.Body>{value}</Media.Body>
    </Media>
  );
}

export default BasicMediaDetails;
