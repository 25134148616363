export function getRandomWaitMessage() {
  const alternatives = [
    "Kindly hold on...",
    "Hang on tight...",
    "Just a moment...",
    "Give us a second...",
    "Hold the line...",
    "Almost there...",
    "Awaiting completion...",
    "Just a little longer...",
    "Sit tight...",
    "We're working on it...",
    "Saglit lang po...",
  ];

  // Get a random entry
  return alternatives[Math.floor(Math.random() * alternatives.length)];
}
